import pageStyles from '../styles/page-common.module.css';
import { LONG_NAMES } from '../constants';
import { NavLink } from 'react-router-dom';


function FAQs() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h2>FAQs</h2>
      
      <h3>What is Polygloss?</h3>
      <p>Polygloss is a web app you can use to help you learn a new language.</p>
      <p> Specifically, it will help you with your reading skills, by showing you lots of short passages of text at an appropriate difficulty for your level.</p>
      <p>The passages are short, self-contained, interesting, and are constantly being changed and regenerated, so you'll constantly be encountering fresh content.</p>
      <br/>
      
      <h3>How does it work?</h3>
      <p>In each session, you get shown a series of passages in your target language.</p>
      <p>Try and read them, then tell the app how easy you found it. The app will adjust to your level.</p>
      <br/>
      
      <h3>Which languages can I learn?</h3>
      <ul>
        {Object.values(LONG_NAMES).map((value, index)=><li key={index}>{value}</li>)}
      </ul>
      <p>We're planning on adding more languages in future!</p>
      <p>Currently, the app is aimed at learners who already speak English. This will change in future.</p>
      <br/>

      <h3>How difficult is the reading material?</h3>
      <p>Each language on Polygloss has difficulty levels from 1 (beginner) to 35 (advanced). The aim is for <a rel="noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/Common_European_Framework_of_Reference_for_Languages">CEFR</a> A1 content at levels 6-10, A2 at levels 11-15, and so on until C2 at levels 31-35.</p>
      <p>For a given language, the difficulty level will adjust to you as you read more and more passages. Alternatively, you can manually change the difficulty in the settings menu.</p>
      <br/>

      <h3>How much does it cost?</h3>
      <p>There is a 2 week free trial, then a very small monthly subscription. The exact cost of this varies depending on where you are in the world. See the <NavLink to='/pricing'>Pricing</NavLink> page.</p>
      <br/>

      <h3>How do I delete my account / cancel my subscription?</h3>
      <p>You can delete your account at any time (including during the free trial) within the Settings menu when logged in. This will end your subscription payments immediately.</p>
      <p>Alternatively, our payment provider (Paddle) will send you email receipts, which will contain links to cancel your subscription. Cancelling your subscription this way will also delete your Polygloss account.</p>
      <br/>

      <h3>Who made Polygloss?</h3>
      <p>See the <NavLink to='/about'>About Polygloss</NavLink> page.</p>
    </div>
  );
}

export default FAQs;