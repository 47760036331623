import { NavLink } from 'react-router-dom';
import footerStyles from '../styles/component-footer.module.css';

function FooterBar() {
  return (
    <footer className={footerStyles.footer}>
      <nav className={footerStyles.nav}>
        <ul className={footerStyles.links}>
          <li><NavLink to="/about" className={footerStyles.link}>About</NavLink></li>
          <li><NavLink to="/FAQs" className={footerStyles.link}>FAQs</NavLink></li>
          <li><NavLink to="/contact" className={footerStyles.link}>Contact</NavLink></li>
          <li><NavLink to="/pricing" className={footerStyles.link}>Pricing</NavLink></li>
          <li><NavLink to="/privacy" className={footerStyles.link}>Privacy</NavLink></li>
          <li><NavLink to="/terms" className={footerStyles.link}>Terms</NavLink></li>
        </ul>
      </nav>
    </footer>
  );
}

export default FooterBar;