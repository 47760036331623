import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';
import formStyles from '../styles/form-common.module.css';
import { authenticate } from "../authentication";


function Login() {

  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;
  const [loading, setLoading] = useState(false);
  const [failedLogin, setFailedLogin] = useState(false);
  const [loginForm, setLoginForm] = useState({
    username: "",
    password: ""
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: ""
  });

  useEffect(() => {
    if(isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  const validateForm = () => {
    const errors = {
      username: "",
      password: ""
    };

    // Check for username or email being empty
    if (!loginForm.username.trim()) {
      errors.username = "Username is required";
    } else if (!/^[a-zA-Z0-9]+$/.test(loginForm.username)) {
      // Example of basic alphanumeric validation for username
      errors.username = "Username must be alphanumeric";
    }

    // Check for password being empty
    if (!loginForm.password) {
      errors.password = "Password is required";
    }

    setFormErrors(errors);

    // Check if any errors exist
    return !Object.values(errors).some((error) => error !== "");
  };

  async function handleLogin(event) {
    event.preventDefault();
    if (!validateForm()) {
      return; // If form is invalid, don't proceed with login
    }
    setLoading(true);
    try {
      await authenticate(loginForm.username, loginForm.password);
      setLoginForm({
        username: "",
        password: ""
      });
    } catch (error) {
      console.log(error);
      setFailedLogin(true);
    }
    setLoading(false);
    
  }

  function handleChange(event) { 
    const {value, name} = event.target
    setLoginForm(prevNote => ({
        ...prevNote, [name]: value})
    )
    return
  }

  return (
    <div className={pageStyles.styledPage}>
      <h2>Login</h2>
      {failedLogin && <p>Login failed. Please check your credentials</p>}
      <form className={formStyles.styledForm} onSubmit={handleLogin}>
        <label htmlFor="username">Username:</label>
        <input onChange={handleChange} 
              type="text"
              text={loginForm.username}
              name="username" 
              placeholder="Username" 
              value={loginForm.username} />
        {formErrors.username && <p style={{ color: 'red' }}>{formErrors.username}</p>}
        <label htmlFor="password">Password:</label>
        <input onChange={handleChange} 
              type="password"
              text={loginForm.password} 
              name="password" 
              placeholder="Password" 
              value={loginForm.password} />
        {formErrors.password && <p style={{ color: 'red' }}>{formErrors.password}</p>}
        <button type="submit" disabled={loading}>
          {loading ? 'Signing In...' : 'Sign In'}
        </button>
      </form>
      <p><NavLink to='/forgotten-username'>Forgotten your username?</NavLink></p>
      <p><NavLink to='/forgotten-password'>Forgotten your password?</NavLink></p>
      <p>Or, if you don't have an account yet, register for one <NavLink to="/register">here</NavLink>.</p>
    </div>
  );
}

export default Login;