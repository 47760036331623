import axios from "axios";

// function to get named cookie from browser
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}


function logError(error) {
  console.log(error);
  if(error.response) {
    console.log(error.response);
    console.log(error.response.status);
    console.log(error.response.headers);
  }
}

const api = axios.create({
  baseURL: process.env.NODE_ENV==='development' ? "http://localhost:5000" : "https://server.polygloss.io",
  withCredentials: true,
  headers: {
      "Content-type": "application/json",
  },
});


async function fetchToken(username, password) {
  try {
    const response = await api.post('/login',  {username: username, password: password});
    return response;
  }
  catch (error) {
    throw error;
  } 
}

async function checkToken(username) {
  return api.post('/check',  {username: username}, {headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}})
  .then(() => {
    return true;
  })
  .catch(error => {
    return false;
  })
}

async function confirmUser(token) {
  if (!token || typeof token !== 'string') {
    throw new Error('Invalid token provided');
  }

  try {
    const response = await api.post('/confirm', { token });
    return response;
  } catch (error) {
    console.error('Error confirming user:', error);
    throw error;
  }
}

async function resendConfirmation(email) {
    const response = await api.post('/resend-confirmation', { email });
    return response;
}

async function sendForgottenUsernameEmail(email) {
  const response = await api.post('/forgotten-username', { email });
  return response;
}


async function sendForgottenPasswordEmail(email, username) {
  const response = await api.post('/forgotten-password', { email, username });
  return response;
}


async function resetPassword(username, password, token) {
  const response = await api.post('/reset-password', { username, password, token });
  return response;
}

async function checkRegDetails(userData) {
  const response = await api.post('/validate-registration-data', userData)
  return response;
}

async function createUser(userData) {
  const response = await api.post('/users', userData)
  return response;
}

async function deleteUser(username) {
  const response = await api.delete(
    `/users/${username}`,
    {headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}}
  )
  return response;
}

async function getUserData(username) {
  try {
    const response = await api.get(
      `/users/${username}`,
      {headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}}
    )
    return response.data.user_data
  } catch (error) {
    logError(error);
    return;
  }
}

async function updateUserData(username, userData) {
  try {
    await api.patch(
      `/users/${username}`,
      userData,
      {headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}}
    )
    return
  } catch (error) {
    logError(error);
    return;
  }
}

async function getPassages(language, level) {
  try {
    const response = await api.get(
      `passages?language=${language}&level=${level}`,
      {headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}}
    )
    return response.data.passages;
  } catch (error) {
    logError(error);
    return;
  }
}

export { fetchToken, checkToken, confirmUser, resendConfirmation, sendForgottenUsernameEmail, sendForgottenPasswordEmail, resetPassword, checkRegDetails, getUserData, updateUserData, getPassages, createUser, deleteUser }
